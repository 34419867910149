<template>
  <div class="shorttv my-page-wrap">
    <div class="inner">
      <div class="content">
        <div class="left">
          <img src="~@/assets/imgs/left.png" alt="" />
        </div>
        <div class="center main-content">
          <div class="list">
            <div class="card" v-for="item in list" :key="item.id" @click="playShow(item)">
              <div class="thumb">
                <img :src="$baseRemote + item.pc_cover_img" class="image" />
                <div class="img_mask">
                  <img src="~@/assets/imgs/play-icon.png" alt="" class="play-icon" style="width: 40px; height: 40px" />
                </div>
              </div>

              <div class="right">
                <h2>{{ item.shortv_title }}</h2>
                <div class="date-num">
                  <i class="el-icon-time"></i>
                  <span style="margin-right: 20px">发布时间: {{ dateFormat(item.create_time)}}</span>
                  <!-- <i class="el-icon-view"></i> -->
                  <!-- <span style="margin-right: 20px">浏览次数: {{ item.read_num || 0 }}次</span> -->
                </div>

                <div class="subscribe">
                  <p>{{ item.pc_describe }}...</p>
                </div>

                <div class="btn">
                  <el-button icon="el-icon-video-play" type="danger" size="mini">播放</el-button>
                </div>
              </div>
            </div>
          </div>

          <div class="pagination">
            <el-pagination layout="prev, pager, next" :total="count" @current-change="handleCurrentChange" :page-size="pageSize"></el-pagination>
          </div>
        </div>
        <div class="right">
          <img src="~@/assets/imgs/right.png" alt="" />
        </div>
      </div>
    </div>

    <div class="video-show" v-if="vedioSrc">
      <div class="videos_wrap">
        <button type="button" @click="vedioSrc = ''"><i class="el-icon-error"></i></button>
        <video :src="vedioSrc" type="video/mp4" controls="true" autoplay="autoplay"></video>
      </div>
    </div>
  </div>
</template>

<script>
  import http from "@/utils/http"

  export default {
    data() {
      return {
        pageSize: 10,
        curPage: 1,
        count: 0,

        list: [],

        vedioSrc: ""
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      getList() {
        http({
          url: "/api/shortv/page",
          data: {
            page: this.curPage,
            page_size: this.pageSize
          }
        }).then((res) => {
          this.count = res.data.count
          const list = res.data.list

          // if (this.curPage == 1) {
            this.list = list
          // } else {
          //   this.list = this.list.concat(list)
          // }
        })
      },

      handleCurrentChange(val) {
        this.curPage = val
        this.getList()
      },

      playShow(item) {
        this.vedioSrc = this.$baseRemote + item.shortv_url
      },
      dateFormat(timestamp) {
        // 创建一个新的Date对象，将时间戳作为参数传入
        var date = new Date(timestamp * 1000);

        // 获取年、月、日、时、分、秒
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();

        // 将月、日、小时、分钟和秒转换为两位数，如果不足两位则补零
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        // 组合年、月、日、小时、分钟和秒，以"YYYY-MM-DD HH:MM:SS"的格式输出
        var formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedTime;
      }
    }
  }
</script>

<style lang="scss">
  .shorttv {
    .pagination {
      .active {
        background: #a20000;
        color: white;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .shorttv {
    background: #f9f9f9;
    .list {
      .card {
        background-color: #fff;
        display: flex;
        margin-bottom: 25px;
        // border-radius: 1px;
        // border: 1px solid #e6e6e6;
        padding: 18px;
        cursor: pointer;

        .thumb {
          border-radius: 8px;
          width: 18%;
          float: left;
          margin-right: 20px;
          max-height: 140px;
          overflow: hidden;
          position: relative;
          .img_mask {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.6);
            // display: flex;
            display: none;
            justify-content: center;
            align-items: center;
            .play-icon {
              width: 40px;
              height: 40px;
            }
          }
        }
        .image {
          max-width: 100%;
          transition: 0.6s 0.05s;
        }

        .right {
          flex: 1;
          text-align: left;
          h2 {
            font-size: 20px;
            margin-bottom: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .date-num {
          color: #999999;
          font-size: 12px;
          margin-bottom: 10px;
        }
        .btn .el-button {
          margin-top: 15px;
          width: 101px;
          font-size:16px;
          height:40px;
            background: #a20000;
            border-radius:6px;
          border: none;
         i{
          font-size:30px;
          transform:scale(1.5);
         }
        }
      }
      .card:hover {
        // border: 1px solid #a20000;
        .img_mask {
          display: flex;
        }
        .image {
          transform: scale(1.1);
        }
        h2 {
          color: #a20000;
        }
        .subscribe {
          color: #a20000;
        }

        .el-button {
         opacity:.88;
        }
      }
      .subscribe {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }

    .pagination {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .video-show {
      width: 100%;
      height: 100%;
      position: fixed;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      background: rgba(0, 0, 0, 0.8);
      overflow-y: auto;
      .videos_wrap {
        max-width: 1200px;
        position: absolute;
        box-sizing: content-box;
        padding: 20px;
        width: 60%;
        // max-height: 60%;
        // top: 20%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background: #fff;
        button {
          position: absolute;
          right: 0;
          top: -70px;
          background: #fff;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          outline: none;
          cursor: pointer;
        }
        video {
          width: 100%;
          max-height: 60vh;
        }
      }
    }
  }
</style>
